import React from "react"
import LRLogo from "../images/logo-landerandrogers.svg"




const Footer = () => (

    <footer className="flex flex-wrap justify-between bg-black text-white py-8 px-6">
        <div className="mx-5 w-56 flex-grow-0 hidden lg:block">
            <a href="https://landers.com.au/" target="_blank" rel="noreferrer">
                <img src={LRLogo} className="block h-10 mb-0" alt="Lander & Rogers Lawyers" />
            </a>   
        </div>
        <div className="text-xs flex-grow px-10 text-center">
        © Lander & Rogers 2022 | <a href="/Contact/" rel="noreferrer" >Contact</a>
        </div>
        <div className="mx-5 w-56 flex-grow-0 hidden lg:block">
            &nbsp;

        </div>


    </footer>

)




export default Footer