import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Logo from "../images/logo-lawtechhub.svg"


function Header ({ siteTitle }) {

  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header className="bg-gray-100 border-white border-solid border-b mb-0">
      <nav className="flex items-stretch flex-wrap justify-between">
        <div className="flex items-center text-white bg-white w-40 px-3 py-2 border-r border-gray-300">
          <Link to="/">
                <img src={Logo} className="block h-16 mb-0 mt-0 ml-2 w-60" alt="Lawtech Hub" />
          </Link>
        </div>
        <div className="block xl:hidden self-center">
          <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 mr-2 text-black focus:outline-none">
            <svg className={`${ isExpanded ? `block` : `hidden` } fill-current h-6 w-6 `} xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            </svg>
            <svg className={`${ isExpanded ? `hidden` : `block` } fill-current h-6 w-6 `} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
            </svg>
          </button>
        </div>
        <div className={`${ isExpanded ? `block w-full` : `hidden` } xl:flex xl:w-auto justify-end`}>
          <div className={`${ isExpanded ? `hamburger h-full w-full pb-0 text-center` : `flex` } font-bold uppercase text-m bg-black h-20 mr-20`}>
              <Link to="/residents" activeClassName="navActive"  className="topnavlink" partiallyActive={true}>Residents</Link>
              <Link to="/mentors" activeClassName="navActive"  className="topnavlink" partiallyActive={true}>Mentors</Link>
              <Link to="/benefits" activeClassName="navActive"  className="topnavlink" partiallyActive={true}>Benefits</Link>
              <Link to="/alumni" activeClassName="navActive"  className="topnavlink" partiallyActive={true}>Alumni</Link>
              <Link to="/playbook/" activeClassName="navActive" className="topnavlink"  partiallyActive={true}>Playbook</Link>
              <Link to="/program" activeClassName="navActive"  className="topnavlink" partiallyActive={true}>Program</Link>
              <Link to="/contact" activeClassName="navActive"  className="topnavlink" partiallyActive={true}>Contact</Link>
          </div>
        </div>
      </nav>
    </header>
  )
}
  


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header